import React, { useEffect, useState } from 'react';
import axios from 'axios';


function Topnav() {

     const auth = localStorage.getItem("auth");
     const user = localStorage.getItem('user');

     const [data, setData] = useState();

     useEffect(() => {
          const get = async () => {
               await axios({
                    method: "get",
                    url: `https://api.destivictsolutions.com/users/${user}`,
                    headers: {
                         "Content-Type": 'application/json',
                         "Accept": "*",
                         "Authorization": auth
                    },
               })
               .then(res => {
                    setData(res.data[0])
               })
               .catch(error => console.log(error))
          }

          get();
     })

     return (
          <div id='topnav'>
               <span>{localStorage.getItem("PSlide") ? 'Details' : (window.location.pathname == '/' ? 'Bookings' : window.location.pathname.substring(1)[0].toUpperCase() + window.location.pathname.substring(2))}</span>
               <span style={{width: "20%"}}>
                    <i class="fa-solid fa-user-tie"></i>
                    {data?.user_role}
               </span>
          </div>
     )
}

export default Topnav;