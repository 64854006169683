import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Sidebar from '../Components/Sidebar.js';
import Topnav from '../Components/Topnav.js';

import '../Assets/Styles/Pages/Loyalty.css';
import { useNavigate } from 'react-router-dom';

function Loyalty() {

     const auth = localStorage.getItem("auth");
     const navigate = useNavigate()

     const [data, setData] =useState()
     const [fData, setFData] = useState()

     useEffect(() => {
          !auth && navigate("/login")

          localStorage.removeItem('PSlide');
          sessionStorage.removeItem('filter');

          const get = async () => {
               await axios({
                    method: "get",
                    url: "https://api.destivictsolutions.com/bookings",
                    headers: {
                         "Content-Type": 'application/json',
                         "Accept": "*",
                         "Authorization": auth
                    }
               })
               .then(res => {
                    setData(res.data);
               })
               .catch(error => console.log(error))
          }

          const fleet = async () => {
               await axios({
                    method: "get",
                    url: "https://api.destivictsolutions.com/fleets",
                    headers: {
                         "Content-Type": 'application/json',
                         "Accept": "*",
                         "Authorization": auth
                    }
               })
               .then(res => {
                    setFData(res.data);
               })
               .catch(error => console.log(error))
          }
          
          get()

          fleet()

     }, [auth, navigate])

     const [showFilter, setShowFilter] = useState(false);

     const [searchItem, updateSearchItem] = useState("");

     const [firstElement, setFirstElement] = useState(true);

     const [popFilt, setPopFilt] = useState("");
     const [filtStatus, setFiltStatus] = useState("");

     const [secondElementRust, setSecondElementRust] = useState(false);
     const [secondElementFleet, setSecondElementFleet] = useState(false);
     const [secondElementTire, setSecondElementTire] = useState(false);
     const [secondElementRef, setSecondElementRef] = useState(false);

     const [rustLoyalty, setRustLoyalty] = useState(true);
     const [fleetLoyalty, setFleetLoyalty] = useState(false);
     const [tireLoyalty, setTireLoyalty] = useState(false);
     const [referralBonus, setReferralBonus] = useState(false);

     let count = 0;

     return (
          <>
               <div id='Loyalty'>
                    <Sidebar/>
                    <main>
                         <Topnav/>
                         <div id='loyalnav'>
                              <span style={{color: (rustLoyalty ? '#FF6A00' : '')}} onClick={() => {setRustLoyalty(true); setFleetLoyalty(false); setTireLoyalty(false); setReferralBonus(false); setSecondElementRust(false) ;setSecondElementTire(false); setSecondElementFleet(false); setSecondElementRef(false);}}>Rust Care Loyalty</span>
                              <span style={{color: (fleetLoyalty ? '#FF6A00' : '')}} onClick={() => {setRustLoyalty(false); setFleetLoyalty(true); setTireLoyalty(false); setReferralBonus(false); setSecondElementRust(false) ;setSecondElementTire(false); setSecondElementFleet(false); setSecondElementRef(false);}}>Fleet Maintenance Loyalty</span>
                              <span style={{color: (tireLoyalty ? '#FF6A00' : '')}} onClick={() => {setRustLoyalty(false); setFleetLoyalty(false); setTireLoyalty(true); setReferralBonus(false);setSecondElementRust(false) ;setSecondElementTire(false); setSecondElementFleet(false); setSecondElementRef(false);}}>Tire Change Loyalty</span>
                              <span style={{color: (referralBonus ? '#FF6A00' : '')}} onClick={() => {setRustLoyalty(false); setFleetLoyalty(false); setTireLoyalty(false); setReferralBonus(true); setSecondElementRust(false) ;setSecondElementTire(false); setSecondElementFleet(false); setSecondElementRef(false);}}>Referral Bonus</span>
                         </div>
                         {firstElement ?
                              <div id='maincnt'>
                                   <div id='maintop'>
                                        <span>Qualified Clients {}</span>
                                        <input type='search' placeholder='Search' onChange={(e) => updateSearchItem(e.target.value)}/>
                                        <span id='flt' onClick={() => {setShowFilter(true); sessionStorage.setItem('filter', 'open')}}>
                                             <i className="fa-solid fa-filter"></i>
                                             Filters
                                        </span>
                                   </div>
                                   {rustLoyalty ?
                                        <table>
                                             <thead>
                                                  <tr>
                                                       <td>S/N
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Client Name
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Post Code
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Vehicle Type
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Section booked
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Booking Date
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Email
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Phone Number
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>
                                                            More
                                                       </td>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                       .map((dat, i) => 
                                                       dat.service_type == "Rust Proofing" && dat.status == "Completed" && (data.filter((x) => (x.status == "Completed") && x.service_type == "Rust Proofing" && x.email == dat.email).length >= 3) && (
                                                            <tr key={i}>
                                                                 <td>{count ++}</td>
                                                                 <td>{dat.firstname + " " + dat.lastname.slice(0, 1).toUpperCase()}</td>
                                                                 <td>{dat.appoint_zipcode}</td>
                                                                 <td>{dat.specific_vehicle}</td>
                                                                 <td style={{paddingLeft: "4%"}}>{data.filter((x) => x.service_type == "Rust Proofing" && (x.status == "Completed")).length}</td>
                                                                 <td>{dat.appoint_date.slice(0, 10)}</td>
                                                                 <td>{dat.email}</td>
                                                                 <td>{dat.phone}</td>
                                                                 <td onClick={() => {setRustLoyalty(false); setSecondElementRust(true); localStorage.setItem("PSlide", 'Details'); localStorage.setItem("userid", dat.id)}}>
                                                                      <i className="fa-solid fa-ellipsis-vertical"></i>
                                                                 </td>
                                                            </tr>
                                                       )
                                                  )}
                                             </tbody>
                                        </table>
                                   : ''}
                                   {secondElementRust ? 
                                        <div id='maindt'>
                                             <div id='dtnav'>
                                                  {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                       .map((dat, i) =>
                                                  (dat.id == localStorage.getItem("userid") &&
                                                       <span key={i}>
                                                            <i onClick={() => {setRustLoyalty(true); setSecondElementRust(false); localStorage.removeItem('PSlide')}} className="fa-solid fa-arrow-left-long"></i>
                                                            <p style={{fontSize: "12px"}}>{dat.firstname + " " + dat.lastname}</p>
                                                       </span>
                                                  ))}
                                                  <span>
                                                       <i className="fa-solid fa-phone"></i>
                                                       <i className="fa-solid fa-envelope"></i>
                                                  </span>
                                             </div>
                                             <div id='dtmain'>
                                                  <div id='dtmainhd'>Booking History</div>
                                                  <table>
                                                       <thead>
                                                            <tr>
                                                                 <td style={{display: "unset", paddingLeft: "0px"}}>Booking Number</td>
                                                                 <td>Service Booked
                                                                      <i className="fa-solid fa-down-long"></i>
                                                                 </td>
                                                                 <td>Status</td>
                                                                 <td>Cost
                                                                      <i className="fa-solid fa-down-long"></i>
                                                                 </td>
                                                            </tr>
                                                       </thead>
                                                       <tbody>
                                                            {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                                 .map((dat, i) =>
                                                            (dat.id == localStorage.getItem("userid") &&
                                                                 <tr key={i}>
                                                                      <td style={{display: "unset"}}>{dat.id.slice(0, 8)}</td>
                                                                      <td>{dat.service_type}</td>
                                                                      <td>{dat.status}</td>
                                                                      <td>{}</td>
                                                                 </tr>
                                                            ))}
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   : ""}
                                   {fleetLoyalty ? 
                                        <table>
                                             <thead>
                                                  <tr>
                                                       <td>S/N
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Client Name
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Post Code
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Vehicle Type
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Section booked
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Booking Date
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Email
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Phone Number
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>
                                                            More
                                                       </td>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                       .map((dat, i) => 
                                                       dat.service_type == "Fleet Service" && dat.status == "Completed" && (fData.filter((x) => x.bookings_id == dat.id && (x.vehicle_count >= 5)).length >= 1) && (
                                                            <tr key={i}>
                                                                 <td>{count ++}</td>
                                                                 <td>{dat.firstname + " " + dat.lastname.slice(0, 1).toUpperCase()}</td>
                                                                 <td>{dat.appoint_zipcode}</td>
                                                                 <td>{dat.specific_vehicle}</td>
                                                                 <td style={{paddingLeft: "4%"}}>{data.filter((x) => x.service_type == "Fleet Service" && (x.status == "Completed")).length}</td>
                                                                 <td>{dat.appoint_date.slice(0, 10)}</td>
                                                                 <td>{dat.email}</td>
                                                                 <td>{dat.phone}</td>
                                                                 <td onClick={() => {setFleetLoyalty(false); setSecondElementFleet(true); localStorage.setItem("PSlide", 'Details')}}>
                                                                      <i className="fa-solid fa-ellipsis-vertical"></i>
                                                                 </td>
                                                            </tr>
                                                       ))}
                                             </tbody>
                                        </table> 
                                   : ""}
                                   {secondElementFleet ? 
                                        <div id='maindt'>
                                             <div id='dtnav'>
                                                  {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                       .map((dat, i) => 
                                                       (dat.id == localStorage.getItem("userid") &&
                                                       <span key={i}>
                                                            <i onClick={() => {setFleetLoyalty(true); setSecondElementFleet(false); localStorage.removeItem('PSlide')}} className="fa-solid fa-arrow-left-long"></i>
                                                            <p style={{fontSize: "12px"}}>{dat.firstname + " " + dat.lastname}</p>
                                                       </span>
                                                  ))}
                                                  <span>
                                                       <i className="fa-solid fa-phone"></i>
                                                       <i className="fa-solid fa-envelope"></i>
                                                  </span>
                                             </div>
                                             <div id='dtmain'>
                                                  <div id='dtmainhd'>Booking History</div>
                                                  <table>
                                                       <thead>
                                                            <tr>
                                                                 <td style={{display: "unset", paddingLeft: "0px"}}>Booking Number</td>
                                                                 <td>Service Booked
                                                                      <i className="fa-solid fa-down-long"></i>
                                                                 </td>
                                                                 <td>Status</td>
                                                                 <td>Cost
                                                                      <i className="fa-solid fa-down-long"></i>
                                                                 </td>
                                                            </tr>
                                                       </thead>
                                                       <tbody>
                                                            {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                                 .map((dat, i) =>
                                                            (dat.id == localStorage.getItem("userid") &&
                                                                 <tr key={i}>
                                                                      <td style={{display: "unset"}}>{dat.id.slice(0, 8)}</td>
                                                                      <td>{dat.service_type}</td>
                                                                      <td>{dat.status}</td>
                                                                      <td>{}</td>
                                                                 </tr>
                                                            ))}
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   : ""}
                                   {tireLoyalty ? 
                                        <table>
                                             <thead>
                                                  <tr>
                                                       <td>S/N
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Client Name
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Post Code
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Vehicle Type
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Section booked
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Booking Date
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Email
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Phone Number
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>
                                                            More
                                                       </td>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                       .map((dat, i) => 
                                                       dat.service_type == "Tire Change" && dat.status == "Completed" && (data.filter((x) => x.service_type == "Tire Change" && (x.status == "Completed")).length >= 3) && (
                                                       <tr key={i}>
                                                            <td>{count ++}</td>
                                                            <td>{dat.firstname + " " + dat.lastname.slice(0, 1).toUpperCase()}</td>
                                                            <td>{dat.appoint_zipcode}</td>
                                                            <td>{dat.specific_vehicle}</td>
                                                            <td style={{paddingLeft: "4%"}}>{data.filter((x) => x.service_type == "Tire Change" && (x.status == "Completed")).length}</td>
                                                            <td>{dat.appoint_date.slice(0, 10)}</td>
                                                            <td>{dat.email}</td>
                                                            <td>{dat.phone}</td>
                                                            <td onClick={() => {setTireLoyalty(false); setSecondElementTire(true); localStorage.setItem("PSlide", 'Details')}}>
                                                                 <i className="fa-solid fa-ellipsis-vertical"></i>
                                                            </td>
                                                       </tr>
                                                  ))}
                                             </tbody>
                                        </table>
                                   :
                                   secondElementTire ? 
                                        <div id='maindt'>
                                             <div id='dtnav'>
                                                  {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                       .map((dat, i) => 
                                                       (dat.id == localStorage.getItem("userid") &&
                                                       <span key={i}>
                                                            <i onClick={() => {setTireLoyalty(true); setSecondElementTire(false); localStorage.removeItem('PSlide')}} className="fa-solid fa-arrow-left-long"></i>
                                                            <p>{}</p>
                                                       </span>
                                                  ))}
                                                  <span>
                                                       <i className="fa-solid fa-phone"></i>
                                                       <i className="fa-solid fa-envelope"></i>
                                                  </span>
                                             </div>
                                             <div id='dtmain'>
                                                  <div id='dtmainhd'>Booking History</div>
                                                  <table>
                                                       <thead>
                                                            <tr>
                                                                 <td style={{display: "unset", paddingLeft: "0px"}}>Booking Number</td>
                                                                 <td>Service Booked
                                                                      <i className="fa-solid fa-down-long"></i>
                                                                 </td>
                                                                 <td>Status</td>
                                                                 <td>Cost
                                                                      <i className="fa-solid fa-down-long"></i>
                                                                 </td>
                                                            </tr>
                                                       </thead>
                                                       <tbody>
                                                            {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                                 .map((dat, i) =>
                                                            (dat.id == localStorage.getItem("userid") &&
                                                                 <tr key={i}>
                                                                      <td style={{display: "unset"}}>{dat.id.slice(0, 8)}</td>
                                                                      <td>{dat.service_type}</td>
                                                                      <td>{dat.status}</td>
                                                                      <td>{}</td>
                                                                 </tr>
                                                            ))}
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   : ""}
                                   {referralBonus ? 
                                        <table>
                                             <thead>
                                                  <tr>
                                                       <td>S/N
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Client Name
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Post Code
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Vehicle Type
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Section booked
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Booking Date
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Email
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>Phone Number
                                                            <i className="fa-solid fa-down-long"></i>
                                                       </td>
                                                       <td>
                                                            More
                                                       </td>
                                                  </tr>
                                             </thead>
                                             <tbody>
{/*                                                   {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
     .map((dat, i) => 
                                                  dat.service_type == "Tire Change" && (data.filter((x) => x.email == dat.email).length >= 3) && dat.status == "Completed" && (
                                                       <tr key={i}>
                                                            <td>{count ++}</td>
                                                            <td>{dat.firstname + " " + dat.lastname.slice(0, 1).toUpperCase()}</td>
                                                            <td>{dat.appoint_zipcode}</td>
                                                            <td>{dat.specific_vehicle}</td>
                                                            <td style={{paddingLeft: "4%"}}>{data.filter((x) => x.status == "Tire Change").length}</td>
                                                            <td>{dat.appoint_date.slice(0, 10)}</td>
                                                            <td>{dat.email}</td>
                                                            <td>{dat.phone}</td>
                                                            <td onClick={() => {setReferralBonus(false); setSecondElementRef(true); localStorage.setItem("PSlide", 'Details')}}>
                                                                 <i className="fa-solid fa-ellipsis-vertical"></i>
                                                            </td>
                                                       </tr>
                                                  ))} */}
                                             </tbody>
                                        </table>
                                   :""}
                                   {secondElementRef ? 
                                        <div id='maindt'>
                                             <div id='dtnav'>
                                                  {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                       .map((dat, i) => 
                                                       (dat.id == localStorage.getItem("userid") &&
                                                       <span key={i}>
                                                            <i onClick={() => {setReferralBonus(true); setSecondElementRef(false); localStorage.removeItem('PSlide')}} className="fa-solid fa-arrow-left-long"></i>
                                                            <p style={{fontSize: "12px"}}>{dat.firstname + " " + dat.lastname}</p>
                                                       </span>
                                                       ))}
                                                  <span>
                                                       <i className="fa-solid fa-phone"></i>
                                                       <i className="fa-solid fa-envelope"></i>
                                                  </span>
                                             </div>
                                             <div id='dtmain'>
                                                  <div id='dtmainhd'>Booking History</div>
                                                  <table>
                                                       <thead>
                                                            <tr>
                                                                 <td style={{display: "unset", paddingLeft: "0px"}}>Booking Number</td>
                                                                 <td>Service Booked
                                                                      <i className="fa-solid fa-down-long"></i>
                                                                 </td>
                                                                 <td>Status</td>
                                                                 <td>Cost
                                                                      <i className="fa-solid fa-down-long"></i>
                                                                 </td>
                                                            </tr>
                                                       </thead>
                                                       <tbody>
                                                            {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                                                 .map((dat, i) =>
                                                            (dat.id == localStorage.getItem("userid") &&
                                                                 <tr key={i}>
                                                                      <td style={{display: "unset"}}>{dat.id.slice(0, 8)}</td>
                                                                      <td>{dat.service_type}</td>
                                                                      <td>{dat.status}</td>
                                                                      <td>{}</td>
                                                                 </tr>
                                                            ))}
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   : ""}
                              </div>
                         : "" }
                    </main>
               </div>
               <div id='filtercv' style={{display: (showFilter == false ? 'none' : "grid")}}>
                    <span id='filter'>
                         <div>
                              <span>Filter by</span>
                              <span style={{cursor: 'pointer', fontSize: '40px'}} onClick={() => {setShowFilter(false); sessionStorage.removeItem('filter')}}>{'\u00D7'}</span>
                         </div>
                         <div>
                              <span>Sort by</span>
                              <span>
                                   <select value={popFilt} onChange={e => setPopFilt(e.target.value)}>
                                        <option></option>
                                        <option>Newest</option>
                                        <option>Oldest</option>
                                   </select>
                              </span>
                         </div>
                         <div>
                              <span>Staus</span>
                              <span>
                                   <select value={filtStatus} onChange={e => setFiltStatus(e.target.value)}>
                                        <option></option>
                                        <option>Pending</option>
                                        <option>Rejected</option>
                                        <option>In progress</option>
                                        <option>Completed</option>
                                   </select>
                              </span>
                         </div>
                         <div>
                              <button style={{backgroundColor: '#FF6A00'}}>Apply Filters</button>
                              <button>Reset Filters</button>
                         </div>
                    </span>
               </div>
          </>
     )
}

export default Loyalty;