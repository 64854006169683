import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import Topnav from '../Components/Topnav';

import '../Assets/Styles/Pages/Bookings.css';
import axios from 'axios';

function Bookings() {

     const auth = localStorage.getItem("auth");
     const navigate = useNavigate()

     const [data, setData] = useState()
     const [fleet, setFleet] = useState()
     const [vehiclee, setVehiclee] = useState()

     const [searchItem, updateSearchItem] = useState("");

     const [showFilter, setShowFilter] = useState(false);
     const [selectedStatus, setSelectedStatus] = useState("");

     const [firstElement, setFirstElement] = useState(true);
     const [secondElement, setSecondElement] = useState(false);

     const [popFilt, setPopFilt] = useState("");
     const [filtStatus, setFiltStatus] = useState("");

     useEffect(() => {
          !auth && navigate("/login")

          localStorage.removeItem('PSlide');
          sessionStorage.removeItem('filter');

          const get = async () => {
               await axios({
                    method: "get",
                    url: "https://api.destivictsolutions.com/bookings",
                    headers: {
                         "Content-Type": 'application/json',
                         "Accept": "*",
                         "Authorization": auth
                    }
               })
               .then(res => setData(res.data))
               .catch(error => console.log(error))
          }

          const fleet = async () => {
               await axios({
                    method: "get",
                    url: "https://api.destivictsolutions.com/fleets",
                    headers: {
                         "Content-Type": 'application/json',
                         "Accept": "*",
                         "Authorization": auth
                    }
               })
               .then(res => setFleet(res.data))
               .catch(error => console.log(error))
          }

          const vehicle = async () => {
               await axios({
                    method: "get",
                    url: "https://api.destivictsolutions.com/vehicles",
                    headers: {
                         "Content-Type": 'application/json',
                         "Accept": "*",
                         "Authorization": auth
                    }
               })
               .then(res => setVehiclee(res.data))
               .catch(error => console.log(error))
          }

          get()
          fleet()
          vehicle()
     }, [auth, navigate])

     const bkStatus = async () => {
          await axios({
               method: "patch",
               url: `https://api.destivictsolutions.com/bookings/${localStorage.getItem("userid")}`,
               headers: {
                    "Content-Type": 'application/json',
                    "Accept": "*",
                    "Authorization": auth
               },
               data: {
                    status: selectedStatus
               }
          })
          .then(res => {
               res.data == "Status updated..." && alert('Booking Status has been updated');
               localStorage.removeItem("userid");
               window.location.reload();
          })
          .catch(error => console.log(error))
     }

     let count = 0;
     
     /* let dates = [];
     data?.map((item) => dates.push(new Date(item.appoint_date).getFullYear().toString() + "-" + (new Date(item.appoint_date).getMonth() + 1).toString() + "-" + new Date(item.appoint_date).getDate().toString()));
     dates.sort() */
/*      const midDate = dates[Math.floor(dates.length / 2)];
 */
  return (
     <>
          <div id='bookings'>
               <Sidebar/>
               <main>
                    <Topnav/>
                    {firstElement ?
                         <div id='maincnt'>
                              <div id='maintop'>
                                   <span>Bookings {}</span>
                                   <input type='search' placeholder='Search by Email' onChange={(e) => updateSearchItem(e.target.value)}/>
                                   <span id='flt' onClick={() => {setShowFilter(true); sessionStorage.setItem('filter', 'open')}}>
                                        <i className="fa-solid fa-filter"></i>
                                        Filters
                                   </span>
                                   <button>+ Add new</button>
                              </div>
                              <table>
                                   <thead>
                                        <tr>
                                             <td>S/N
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Booking Number
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Client Name
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Post Code
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Vehicle Type
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Service Type
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Booking Date
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Time
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Phone Number
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Status
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>
                                                  More
                                             </td>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                             .sort((a, b) => { return ((new Date(a.appoint_date).getFullYear().toString() + "-" + (new Date(a.appoint_date).getMonth() + 1).toString() + "-" + new Date(a.appoint_date).getDate().toString()) >= (new Date(b.appoint_date).getFullYear().toString() + "-" + (new Date(b.appoint_date).getMonth() + 1).toString() + "-" + new Date(b.appoint_date).getDate().toString()) && (popFilt == "new")) ? -1 : ((new Date(a.appoint_date).getFullYear().toString() + "-" + (new Date(a.appoint_date).getMonth() + 1).toString() + "-" + new Date(a.appoint_date).getDate().toString()) <= (new Date(b.appoint_date).getFullYear().toString() + "-" + (new Date(b.appoint_date).getMonth() + 1).toString() + "-" + new Date(b.appoint_date).getDate().toString()) && (popFilt == "old")) && -1})
                                             .slice(0, 20).map((dat, i) =>
                                             <tr key={i}>
                                                  <td>{count++}</td>
                                                  <td>{dat.id.slice(0, 8)}</td>
                                                  <td>{dat.firstname + " " + dat.lastname[0].toUpperCase()}</td>
                                                  <td>{dat.appoint_zipcode}</td>
                                                  <td>{dat.specific_vehicle}</td>
                                                  <td>{dat.service_type}</td>
                                                  <td>{dat.appoint_date.slice(0, 10)}</td>
                                                  <td>{dat.appoint_time.slice(0, 5)}</td>
                                                  <td>{dat.phone}</td>
                                                  <td style={{color: (dat.status == 'Pending' ? "blue" : dat.status == 'Cancelled' ? 'red' : dat.status == 'In_progress' ? '#FB9B00' : dat.status == "Completed" ? 'green' : 'blue')}}>{dat.status}</td>
                                                  <td onClick={() => {setFirstElement(false); setSecondElement(true); localStorage.setItem("PSlide", 'Details'); localStorage.setItem("userid", dat.id)}}>
                                                       <i className="fa-solid fa-ellipsis-vertical"></i>
                                                  </td>
                                             </tr>
                                        )}
                                   </tbody>
                              </table>
                         </div>
                    : "" }
                    {secondElement ?
                         <div id='maindt'>
                              <div id='dtnav'>
                                   <span>
                                        <i onClick={() => {setFirstElement(true); setSecondElement(false); localStorage.removeItem('PSlide')}} className="fa-solid fa-arrow-left-long"></i>
                                   </span>
                                   <span>
                                        <i className="fa-solid fa-phone"></i>
                                        <i className="fa-solid fa-envelope"></i>
                                   </span>
                              </div>
                              {data?.map((dat, i) =>
                                   (dat.id == localStorage.getItem("userid") &&
                                   <div id='dtmain' key={i}>
                                        <h3>Booking Information</h3>
                                        <div id='clidt'>
                                             <span>
                                                  <p>Email</p>
                                                  <p>{dat.email}</p>
                                             </span>
                                             <span>
                                                  <p>Phone Number</p>
                                                  <p>{dat.phone}</p>
                                             </span>
                                             <span>
                                                  <p>Appointment Address</p>
                                                  <p>{dat.appoint_location}</p>
                                             </span>
                                             <span>
                                                  <p>City, State</p>
                                                  <p>{dat.appoint_city + ", " + dat.appoint_state}</p>
                                             </span>
                                        </div>
                                        {fleet.map((fleet, i) => 
                                             (fleet.bookings_id == dat.id &&
                                                  <>
                                                       <h3>Fleet Details</h3>
                                                       <div id='clidt'>
                                                            <span>
                                                                 <p>Vehicle Count</p>
                                                                 <p>{fleet.vehicle_count}</p>
                                                            </span>
                                                            <span>
                                                                 <p>Vehicle Travel Areas</p>
                                                                 <p>{fleet.vehicle_travel_areas}</p>
                                                            </span>
                                                            <span>
                                                                 <p>Name of contact person</p>
                                                                 <p>{fleet.contact_person}</p>
                                                            </span>
                                                            <span>
                                                                 <p>P.O Instruction</p>
                                                                 <p>{fleet.po_instructions}</p>
                                                            </span>
                                                       </div>
                                                       <div id='clidt' style={{marginTop: "3%"}}>
                                                            <span>
                                                                 <p>Other Instruction</p>
                                                                 <p>{fleet.other_instructions}</p>
                                                            </span>
                                                            <span>
                                                                 <p>Purchase Order</p>
                                                                 <p>{fleet.purchase_order == true ? "Yes" : "No"}</p>
                                                            </span>
                                                            <span>
                                                                 <p>Payment Option</p>
                                                                 <p>{fleet.payment_option}</p>
                                                            </span>
                                                            <span>
                                                                 <p>PST Exemption</p>
                                                                 <p>{fleet.pst_exemption == true ? "Yes" : "No"}</p>
                                                            </span>
                                                            <span>
                                                                 <p>GST Exemption</p>
                                                                 <p>{fleet.gst_exemption == true ? "Yes" : "No"}</p>
                                                            </span>
                                                       </div>
                                                  </>
                                             )
                                        )}
                                        {vehiclee.map((vehiclee, i) => 
                                             (vehiclee.bookings_id == dat.id &&
                                                  <>
                                                       <h3>Vehicle Details</h3>
                                                       <div id='clidt'>
                                                            <span>
                                                                 <p>Make</p>
                                                                 <p>{vehiclee.vehicle_make}</p>
                                                            </span>
                                                            <span>
                                                                 <p>Model</p>
                                                                 <p>{vehiclee.vehicle_model}</p>
                                                            </span>
                                                            <span>
                                                                 <p>Year</p>
                                                                 <p>{vehiclee.vehicle_year}</p>
                                                            </span>
                                                            <span>
                                                                 <p style={{fontSize: '20px'}}>Vehicle Identification Number</p>
                                                                 <p>{vehiclee.vin}</p>
                                                            </span>
                                                       </div>
                                                  </>
                                             )
                                        )}
                                        <h3>Appointment Details</h3>
                                        <div id='clidt'>
                                             <span>
                                                  <p>Date</p>
                                                  <p>{dat.appoint_date.slice(0, 10)}</p>
                                             </span>
                                             <span>
                                                  <p>Time</p>
                                                  <p>{dat.appoint_time}</p>
                                             </span>
                                             <span>
                                                  <p>Location for Service</p>
                                                  <p>{dat.appoint_location}</p>
                                             </span>
                                        </div>
                                        <h3>Additional Requirement</h3>
                                        <div id='clidt'>
                                             <span style={{height: "20%"}}>
                                                  <p>Fleet Size</p>
                                                  <p>{dat.vehicle_count}</p>
                                             </span>
                                             <span style={{height: "0px", width: '75%'}}>
                                                  <p>Special Requests or Concerns</p>
                                                  <p style={{overflowX: 'auto', height: "5%", padding: "30px  10px", textAlign: "start"}}>{dat.concerns}</p>
                                             </span>
                                        </div>
                                        <h3>Billing Information</h3>
                                        <div id='clidt'>
                                             <span>
                                                  <p>Preferred Payment Method</p>
                                                  <p>{dat.payment_means}</p>
                                             </span>
                                             <span>
                                                  <p>Billing Address</p>
                                                  <p>{dat.billing_address}</p>
                                             </span>
                                        </div>
                                        <h3>Confirmation Preference</h3>
                                        <div id='clidt'>
                                             <span style={{display: "unset"}}>
                                                  <p>Payment Confirmation Means</p>
                                                  <p>{dat.pay_conf}</p>
                                             </span>
                                             <span style={{display: "unset"}}>
                                                  <p>Allow Promotions/Follow-Up?</p>
                                                  <p>{dat.allow_promo == true ? "Yes" : "No"}</p>
                                             </span>
                                             <span style={{display: "unset"}}>
                                                  <p>Change Booking Status</p>
                                                  <select style={{width: "-webkit-fill-available", padding: "15px 0px", color: (selectedStatus == 'Cancelled' ? 'red' : selectedStatus == 'In-progress' ? '#FB9B00' : selectedStatus == "Completed" ? 'green' : selectedStatus == "Pending" && 'blue')}} value={selectedStatus} onChange={(e) => (setSelectedStatus(e.target.value), localStorage.setItem("userid", dat.id))}>
                                                       <option>Select Status</option>
                                                       <option value="Pending" style={{color: "blue"}}>Pending</option>
                                                       <option value="In-progress" style={{color: "#FB9B00"}}>In-progress</option>
                                                       <option value="Completed" style={{color: "green"}}>Completed</option>
                                                       <option value="Cancelled" style={{color: "red"}}>Cancelled</option>
                                                  </select>
                                             </span>
                                             <span>
                                                  <p hidden>Click the button to update</p>
                                                  <button id='statusbtn' onClick={bkStatus}>Update Status</button>
                                             </span>
                                        </div>
                                   </div>)
                              )}
                         </div>
                    : "" }
               </main>
          </div>
          <div id='filtercv' style={{display: (showFilter == false ? 'none' : "grid")}}>
               <span id='filter'>
                    <div>
                         <span>Filter by</span>
                         <span style={{cursor: 'pointer', fontSize: '40px'}} onClick={() => {setShowFilter(false); sessionStorage.removeItem('filter')}}>{'\u00D7'}</span>
                    </div>
                    <div>
                         <span>Sort by</span>
                         <span>
                              <select value={popFilt} onChange={e => setPopFilt(e.target.value)}>
                                   <option value=''></option>
                                   <option value='new'>Newest</option>
                                   <option value='old'>Oldest</option>
                              </select>
                         </span>
                    </div>
                    <div>
                         <span>Staus</span>
                         <span>
                              <select value={filtStatus} onChange={e => setFiltStatus(e.target.value)}>
                                   <option value=''></option>
                                   <option value='Pending'>Pending</option>
                                   <option value='Cancelled'>Cancelled</option>
                                   <option value='In-progress'>In progress</option>
                                   <option value='Completed'>Completed</option>
                              </select>
                         </span>
                    </div>
                    <div>
                         <button onClick={() => {setPopFilt(""); setFiltStatus("")}}>Reset Filters</button>
                    </div>
               </span>
          </div>
     </>
  )
}

export default Bookings;