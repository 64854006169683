import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Sidebar from '../Components/Sidebar.js';
import Topnav from '../Components/Topnav.js';

import '../Assets/Styles/Pages/Loyalty.css';

function Pricing() {

     const auth = localStorage.getItem("auth");
     const navigate = useNavigate()

     const [data, setData] = useState()

     useEffect(() => {
          !auth && navigate("/login");
          
          localStorage.removeItem('PSlide');
          localStorage.removeItem('filter');

          const get = async () => {
               await axios({
                    method: "get",
                    url: "https://api.destivictsolutions.com/services",
                    headers: {
                         "Content-Type": 'application/json',
                         "Accept": "*",
                         "Authorization": auth
                    }
               })
               .then(res => setData(res.data))
               .catch(error => console.log(error))
          }

          get()

     }, [auth, navigate])

     const [personalVehicle, setPersonalVehicle] = useState(true);
     const [industrialEquipments, setIndustrialEquipments] = useState(false);     
     const [mobileFleet, setMobileFleet] = useState(false);     
     const [tireChange, setTireChange] = useState(false);
     const [desalting, setDesalting] = useState(false);

     let count = 0;

     return (
          <div id='Loyalty'>
               <Sidebar/>
               <main>
                    <Topnav/>
                    <div id='loyalnav'>
                         <span style={{color: (personalVehicle ? '#FF6A00' : '')}} onClick={() => {setPersonalVehicle(true); setIndustrialEquipments(false); setMobileFleet(false); setTireChange(false); setDesalting(false)}}>Personal Vehicles</span>
                         <span style={{color: (industrialEquipments ? '#FF6A00' : '')}} onClick={() => {setPersonalVehicle(false); setIndustrialEquipments(true); setMobileFleet(false); setTireChange(false); setDesalting(false)}}>Industrial Equipments</span>
                         <span style={{color: (mobileFleet ? '#FF6A00' : '')}} onClick={() => {setPersonalVehicle(false); setIndustrialEquipments(false); setMobileFleet(true); setTireChange(false); setDesalting(false)}}>Mobile Fleet</span>
                         <span style={{color: (tireChange ? '#FF6A00' : '')}} onClick={() => {setPersonalVehicle(false); setIndustrialEquipments(false); setMobileFleet(false); setTireChange(true); setDesalting(false)}}>Tire Change</span>
                         <span style={{color: (desalting ? '#FF6A00' : '')}} onClick={() => {setPersonalVehicle(false); setIndustrialEquipments(false); setMobileFleet(false); setTireChange(false); setDesalting(true)}}>Desalting</span>
                    </div>
                         <div id='maincnt'>
                              <div id='maintop'>
                                   <span style={{fontSize: '22px', fontFamily: 'Inter', width: '100%'}}>{personalVehicle ? 'Personal Vehicle' : industrialEquipments ? "Industrial Equipments" : mobileFleet ? 'Mobile Fleet' : tireChange ? 'Tire Change' : desalting ? 'Desalting' : ''} {}</span>
                              </div>
                              {personalVehicle ?
                                   <table>
                                        <thead>
                                             <tr>
                                                  <td>S/N
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Service Name
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Specification
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Description
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Start Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>End Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Update Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                             </tr>
                                        </thead>
                                        <tbody>
                                             {data?.map((dat, i) => 
                                                  <tr>
                                                       <td>{count ++}</td>
                                                       <td>{dat.service_name}</td>
                                                       <td>{dat.specification}</td>
                                                       <td>{dat.description}</td>
                                                       <td>${dat.start_price}</td>
                                                       <td>${dat.end_price}</td>
                                                       <td style={{cursor: 'unset', width: "100px", display: "flex"}}>
                                                            <input type='number' style={{width: '65%'}}/>
                                                            <button style={{backgroundColor: "green", color: "white"}}>
                                                                 <i class="fa-solid fa-arrow-right"></i>
                                                            </button>
                                                       </td>
                                                  </tr>
                                             )}
                                        </tbody>
                                   </table>
                              : ''}
                              {industrialEquipments ? 
                                   <table>
                                        <thead>
                                             <tr>
                                                  <td>S/N
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Service
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Specification
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Description
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Start Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>End Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Update Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                             </tr>
                                        </thead>
                                        <tbody>
                                             {data?.map((dat, i) => 
                                                  <tr>
                                                       <td>{count ++}</td>
                                                       <td>{dat.service_name}</td>
                                                       <td>{dat.specification}</td>
                                                       <td>{dat.description}</td>
                                                       <td>${dat.start_price}</td>
                                                       <td>${dat.end_price}</td>
                                                       <td style={{cursor: 'unset', width: "100px", display: "flex"}}>
                                                            <input type='number' style={{width: '65%'}}/>
                                                            <button style={{backgroundColor: "green", color: "white"}}>
                                                                 <i class="fa-solid fa-arrow-right"></i>
                                                            </button>
                                                       </td>
                                                  </tr>
                                             )}
                                        </tbody>
                                   </table> 
                              : ''}
                              {mobileFleet ? 
                                   <table>
                                        <thead>
                                             <tr>
                                                  <td>S/N
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Service
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Specification
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Description
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Start Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>End Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Update Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                             </tr>
                                        </thead>
                                        <tbody>
                                             {data?.map((dat, i) => 
                                                  <tr>
                                                       <td>{count ++}</td>
                                                       <td>{dat.service_name}</td>
                                                       <td>{dat.specification}</td>
                                                       <td>{dat.description}</td>
                                                       <td>${dat.start_price}</td>
                                                       <td>${dat.end_price}</td>
                                                       <td style={{cursor: 'unset', width: "100px", display: "flex"}}>
                                                            <input type='number' style={{width: '65%'}}/>
                                                            <button style={{backgroundColor: "green", color: "white"}}>
                                                                 <i class="fa-solid fa-arrow-right"></i>
                                                            </button>
                                                       </td>
                                                  </tr>
                                             )}
                                        </tbody>
                                   </table>
                              : ''}
                              {tireChange ? 
                                   <table>
                                        <thead>
                                             <tr>
                                                  <td>S/N
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Service
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Specification
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Description
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Start Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>End Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Update Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                             </tr>
                                        </thead>
                                        <tbody>
                                             {data?.map((dat, i) => 
                                                  <tr>
                                                       <td>{count ++}</td>
                                                       <td>{dat.service_name}</td>
                                                       <td>{dat.specification}</td>
                                                       <td>{dat.description}</td>
                                                       <td>${dat.start_price}</td>
                                                       <td>${dat.end_price}</td>
                                                       <td style={{cursor: 'unset', width: "100px", display: "flex"}}>
                                                            <input type='number' style={{width: '65%'}}/>
                                                            <button style={{backgroundColor: "green", color: "white"}}>
                                                                 <i class="fa-solid fa-arrow-right"></i>
                                                            </button>
                                                       </td>
                                                  </tr>
                                             )}
                                        </tbody>
                                   </table>
                              : ''}
                              {desalting ? 
                                   <table>
                                        <thead>
                                             <tr>
                                                  <td>S/N
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Service
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Specification
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Description
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Start Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>End Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Update Price
                                                       <i class="fa-solid fa-down-long"></i>
                                                  </td>
                                             </tr>
                                        </thead>
                                        <tbody>
                                             {data?.map((dat, i) => 
                                                  <tr>
                                                       <td>{count ++}</td>
                                                       <td>{dat.service_name}</td>
                                                       <td>{dat.specification}</td>
                                                       <td>{dat.description}</td>
                                                       <td>${dat.start_price}</td>
                                                       <td>${dat.end_price}</td>
                                                       <td style={{cursor: 'unset', width: "100px", display: "flex"}}>
                                                            <input type='number' style={{width: '65%'}}/>
                                                            <button style={{backgroundColor: "green", color: "white"}}>
                                                                 <i class="fa-solid fa-arrow-right"></i>
                                                            </button>
                                                       </td>
                                                  </tr>
                                             )}
                                        </tbody>
                                   </table>
                              : ''}
                         </div>
               </main>
          </div>
     )
}

export default Pricing;