import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../Components/Sidebar.js';
import Topnav from '../Components/Topnav.js';

import '../Assets/Styles/Pages/Loyalty.css';
import '../Assets/Styles/Pages/Calendar.css';

function Calendar() {
     const auth = localStorage.getItem("auth");
     const navigate = useNavigate()

     useEffect(() => {
          !auth && navigate("/login")

          localStorage.removeItem('PSlide');
          localStorage.removeItem('filter');
     }, [auth, navigate])

     const [firstElement, setFirstElement] = useState(true);
     const [secondElement, setSecondElement] = useState(false);

     const [all, setAll] = useState(true);
     const [personalVehicle, setPersonalVehicle] = useState(false);
     const [industrialEquipments, setIndustrialEquipments] = useState(false);     
     const [mobileFleet, setMobileFleet] = useState(false);     
     const [tireChange, setTireChange] = useState(false);
     const [desalting, setDesalting] = useState(false);

     return (
          <div id='Calendar'>
               <Sidebar/>
               <main>
                    <Topnav/>
                    <div id='loyalnav'>
                         <span style={{color: (all ? '#FF6A00' : '')}} onClick={() => {setAll(true);setPersonalVehicle(false); setIndustrialEquipments(false); setMobileFleet(false); setTireChange(false); setDesalting(false)}}>All</span>
                         <span style={{color: (personalVehicle ? '#FF6A00' : '')}} onClick={() => {setAll(false);setPersonalVehicle(true); setIndustrialEquipments(false); setMobileFleet(false); setTireChange(false); setDesalting(false)}}>Personal Vehicles</span>
                         <span style={{color: (industrialEquipments ? '#FF6A00' : '')}} onClick={() => {setAll(false);setPersonalVehicle(false); setIndustrialEquipments(true); setMobileFleet(false); setTireChange(false); setDesalting(false)}}>Industrial Equipments</span>
                         <span style={{color: (mobileFleet ? '#FF6A00' : '')}} onClick={() => {setAll(false);setPersonalVehicle(false); setIndustrialEquipments(false); setMobileFleet(true); setTireChange(false); setDesalting(false)}}>Mobile Fleet</span>
                         <span style={{color: (tireChange ? '#FF6A00' : '')}} onClick={() => {setAll(false);setPersonalVehicle(false); setIndustrialEquipments(false); setMobileFleet(false); setTireChange(true); setDesalting(false)}}>Tire Change</span>
                         <span style={{color: (desalting ? '#FF6A00' : '')}} onClick={() => {setAll(false);setPersonalVehicle(false); setIndustrialEquipments(false); setMobileFleet(false); setTireChange(false); setDesalting(true)}}>Desalting</span>
                    </div>
                    {firstElement ?
                         <div id='maincnt'>
                              <iframe src="https://calendar.google.com/calendar/embed?src=destivictsolutions%40gmail.com&ctz=America%2FToronto" width="800" height="600" frameborder="0" scrolling="no"></iframe>
                              {all ?
                                        <span>
                                             <h2>Upcoming <span style={{color: '#FF6A00'}}>Appointments</span></h2>
                                             <div>
                                                  <span id='day'>Day + Date</span>
                                                  <div id='calflex'>
                                                       <span id='time'>Start - End</span>
                                                       <button onClick={() => {setSecondElement(true); setFirstElement(false)}}>Booking Details</button>
                                                  </div>
                                                  <div>Service name</div>
                                             </div>
                                        </span>
                              : ''}
                              {personalVehicle ? 
                                        <span>
                                             <h2>Upcoming <span style={{color: '#FF6A00'}}>Appointments</span></h2>
                                             <div>
                                                  <span id='day'>Day + Date</span>
                                                  <div id='calflex'>
                                                       <span id='time'>Start - End</span>
                                                       <button onClick={() => {setSecondElement(true); setFirstElement(false)}}>Booking Details</button>
                                                  </div>
                                                  <div>Service name</div>
                                             </div>
                                        </span>
                              : ''}
                              {industrialEquipments ? 
                                        <span>
                                             <h2>Upcoming <span style={{color: '#FF6A00'}}>Appointments</span></h2>
                                             <div>
                                                  <span id='day'>Day + Date</span>
                                                  <div id='calflex'>
                                                       <span id='time'>Start - End</span>
                                                       <button onClick={() => {setSecondElement(true); setFirstElement(false)}}>Booking Details</button>
                                                  </div>
                                                  <div>Service name</div>
                                             </div>
                                        </span>
                              : ''}
                              {mobileFleet ? 
                                        <span>
                                             <h2>Upcoming <span style={{color: '#FF6A00'}}>Appointments</span></h2>
                                             <div>
                                                  <span id='day'>Day + Date</span>
                                                  <div id='calflex'>
                                                       <span id='time'>Start - End</span>
                                                       <button onClick={() => {setSecondElement(true); setFirstElement(false)}}>Booking Details</button>
                                                  </div>
                                                  <div>Service name</div>
                                             </div>
                                        </span>
                              : ''}
                              {tireChange ? 
                                        <span>
                                             <h2>Upcoming <span style={{color: '#FF6A00'}}>Appointments</span></h2>
                                             <div>
                                                  <span id='day'>Day + Date</span>
                                                  <div id='calflex'>
                                                       <span id='time'>Start - End</span>
                                                       <button onClick={() => {setSecondElement(true); setFirstElement(false)}}>Booking Details</button>
                                                  </div>
                                                  <div>Service name</div>
                                             </div>
                                        </span>
                              : ''}
                              {desalting ? 
                                        <span>
                                             <h2>Upcoming <span style={{color: '#FF6A00'}}>Appointments</span></h2>
                                             <div>
                                                  <span id='day'>Day + Date</span>
                                                  <div id='calflex'>
                                                       <span id='time'>Start - End</span>
                                                       <button onClick={() => {setSecondElement(true); setFirstElement(false)}}>Booking Details</button>
                                                  </div>
                                                  <div>Service name</div>
                                             </div>
                                        </span>
                              : ''}
                         </div>
                    : "" }
                    {secondElement ? 
                         <div id='maindt'>
                              <div id='dtnav'>
                                   <span>
                                        <i onClick={() => {setFirstElement(true); setSecondElement(false); localStorage.removeItem('PSlide')}} class="fa-solid fa-arrow-left-long"></i>
                                   </span>
                                   <span>
                                        <i class="fa-solid fa-phone"></i>
                                        <i class="fa-solid fa-envelope"></i>
                                   </span>
                              </div>
                              <div id='dtmain'>
                                   <h3>Client Information</h3>
                                   <div id='clidt'>
                                        <span>
                                             <p>Firstname</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Lastname</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Phone number</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Email</p>
                                             <p></p>
                                        </span>
                                   </div>
                                   <h3>Service Selection</h3>
                                   <div id='clidt'>
                                        <span style={{width: '75%'}}>
                                             <p>Type of Service Required</p>
                                             <span style={{display: 'flex', width: '100%'}}>
                                                  <p></p>
                                                  <p></p>
                                                  <p></p>
                                                  <p></p>
                                                  <p></p>
                                                  <p></p>
                                             </span>
                                        </span>
                                        <span>
                                             <p>Vehicle or Equipment Type</p>
                                             <p></p>
                                        </span>
                                   </div>
                                   <h3>Vehicle Details (if applicable)</h3>
                                   <div id='clidt'>
                                        <span>
                                             <p>Make</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Model</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Year</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p style={{fontSize: '20px'}}>Vehicle Identification Number</p>
                                             <p></p>
                                        </span>
                                   </div>
                                   <h3>Appointment Details</h3>
                                   <div id='clidt'>
                                        <span>
                                             <p>Date</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Time</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Location for Service</p>
                                             <p></p>
                                        </span>
                                   </div>
                                   <h3>Additional Requirement</h3>
                                   <div id='clidt'>
                                        <span>
                                             <p>Fleet Size</p>
                                             <p></p>
                                        </span>
                                        <span style={{width: '75%'}}>
                                             <p>Special Requests or Concerns</p>
                                             <p></p>
                                        </span>
                                   </div>
                                   <h3>Billing Information</h3>
                                   <div id='clidt'>
                                        <span>
                                             <p>Preferred Payment Method</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Billing contact</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Street address</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>City</p>
                                             <p></p>
                                        </span>
                                   </div>
                                   <div id='clidt'>
                                        <span>
                                             <p>State/province</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Zip/postal code</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Phone number</p>
                                             <p></p>
                                        </span>
                                        <span>
                                             <p>Email</p>
                                             <p></p>
                                        </span>
                                   </div>
                                   <h3>Confirmation Preference</h3>
                                   <div id='clidt'>
                                        <span>
                                             <p>Preferred Payment Method</p>
                                             <p></p>
                                        </span>
                                        <span style={{width: '75%'}}>
                                             <p>Consent for Promotions or Follow-Up</p>
                                             <p></p>
                                        </span>
                                   </div>
                              </div>
                         </div>
                    : ""}
               </main>
          </div>
     )
}

export default Calendar;