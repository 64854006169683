import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../Components/Sidebar';
import Topnav from '../Components/Topnav';

import '../Assets/Styles/Pages/Clients.css';
import '../Assets/Styles/Pages/Loyalty.css';

function Clients() {
     const auth = localStorage.getItem("auth");
     const navigate = useNavigate()

     const [data, setData] = useState();
     const [nokn, setNobk] = useState(0);

     const [showFilter, setShowFilter] = useState(false);

     const [firstElement, setFirstElement] = useState(true);
     const [secondElement, setSecondElement] = useState(false);

     const [searchItem, updateSearchItem] = useState("");
     
     const [popFilt, setPopFilt] = useState("");
     
     useEffect(() => {

          !auth && navigate("/login")

          localStorage.removeItem('PSlide');
          sessionStorage.removeItem('filter');

          const get = async () => {
               await axios({
                    method: "get",
                    url: "https://api.destivictsolutions.com/bookings",
                    headers: {
                         "Content-Type": 'application/json',
                         "Accept": "*",
                         "Authorization": auth
                       }
               })
               .then(res => {
                    setData(res.data);
                    for (let i = 0; i < res.data.length; i++) {
                         if (res.data[i].email == localStorage.getItem("sessmail")) {
                              console.log(res.data[i].email)
                              setNobk(nokn + 1)
                         }                         
                    }
               })
               .catch(error => console.log(error))
          }

          get()
     }, [auth, navigate, nokn])

     let count = 0;

  return (
     <>
          <div id='Clients'>
               <Sidebar/>
               <main>
                    <Topnav/>
                    {firstElement ? 
                         <div id='maincnt'>
                              <div id='maintop'>
                                   <span>Clients {}</span>
                                   <input type='search' placeholder='Search by Email' onChange={(e) => updateSearchItem(e.target.value)}/>
                                   <span id='flt' onClick={() => {setShowFilter(true); sessionStorage.setItem('filter', 'open')}}>
                                        <i class="fa-solid fa-filter"></i>
                                        Filters
                                   </span>
                              </div>
                              <table>
                                   <thead>
                                        <tr>
                                             <td>S/N
                                                  <i class="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Client Name
                                                  <i class="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Post Code
                                                  <i class="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Vehicle Type
                                                  <i class="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Sessions booked
                                                  <i class="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Email
                                                  <i class="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Phone Number
                                                  <i class="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>
                                                  More
                                             </td>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {data?.filter((dat) => dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat)
                                        .sort((a, b) => { return ((new Date(a.appoint_date).getFullYear().toString() + "-" + (new Date(a.appoint_date).getMonth() + 1).toString() + "-" + new Date(a.appoint_date).getDate().toString()) >= (new Date(b.appoint_date).getFullYear().toString() + "-" + (new Date(b.appoint_date).getMonth() + 1).toString() + "-" + new Date(b.appoint_date).getDate().toString()) && (popFilt == "new")) ? -1 : ((new Date(a.appoint_date).getFullYear().toString() + "-" + (new Date(a.appoint_date).getMonth() + 1).toString() + "-" + new Date(a.appoint_date).getDate().toString()) <= (new Date(b.appoint_date).getFullYear().toString() + "-" + (new Date(b.appoint_date).getMonth() + 1).toString() + "-" + new Date(b.appoint_date).getDate().toString()) && (popFilt == "old")) && -1})                                        
                                        .slice(0, 20).map((dat, i) => 
                                             dat.status == "Completed" && (
                                                  <tr key={i}>
                                                       <td>{count++}</td>
                                                       <td>{dat.firstname + " " + dat.lastname[0].toUpperCase()}</td>
                                                       <td>{dat.appoint_zipcode}</td>
                                                       <td>{localStorage.setItem("sessmail", dat.email)}{dat.specific_vehicle}</td>
                                                       <td style={{paddingLeft: "4%"}}>{data.filter((x) => x.email == dat.email).length}</td>
                                                       <td>{dat.email}</td>
                                                       <td>{dat.phone}</td>
                                                       <td onClick={() => {setFirstElement(false); setSecondElement(true); localStorage.setItem("PSlide", 'Details'); localStorage.setItem("userid", dat.id)}}>
                                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                                       </td>
                                                  </tr>
                                             )
                                        )}
                                   </tbody>
                              </table>
                         </div>
                    : "" }
                    {secondElement ?
                         <>
                              {data?.map((dat, i) =>
                                   (dat.id == localStorage.getItem("userid") &&
                                        <div id='maindt' key={i}>
                                             <div id='dtnav'>
                                                  <span style={{width: "max-content"}}>
                                                       <i onClick={() => {setFirstElement(true); setSecondElement(false); localStorage.removeItem('PSlide')}} class="fa-solid fa-arrow-left-long"></i>
                                                       <p>{dat.firstname + " " + dat.lastname}</p>
                                                  </span>
                                                  <span>
                                                       <i class="fa-solid fa-phone"></i>
                                                       <i class="fa-solid fa-envelope"></i>
                                                  </span>
                                             </div>
                                             <div id='dtmain'>
                                                  <div id='dtmainhd'>Booking History</div>
                                                  <table>
                                                       <thead>
                                                            <tr>
                                                                 <td style={{textAlign: "left", placeSelf: "left", justifySelf: "left"}}>Booking Number</td>
                                                                 <td style={{textAlign: "left", placeSelf: "left", justifySelf: "left"}}>Service Booked
                                                                      <i class="fa-solid fa-down-long"></i>
                                                                 </td>
                                                                 <td style={{textAlign: "left", placeSelf: "left", justifySelf: "left"}}>Cost
                                                                      <i class="fa-solid fa-down-long"></i>
                                                                 </td>
                                                            </tr>
                                                       </thead>
                                                       <tbody>
                                                            <tr>
                                                                 <td style={{textAlign: "left", placeSelf: "left", justifySelf: "left"}}>{dat.id}</td>
                                                                 <td style={{textAlign: "left", placeSelf: "left", justifySelf: "left"}}>{dat.service_type}</td>
                                                                 <td style={{textAlign: "left", placeSelf: "left", justifySelf: "left"}}></td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   )
                              )}
                         </>
                    : "" }
               </main>
          </div>
          <div id='filtercv' style={{display: (showFilter == false ? 'none' : "grid")}}>
               <span id='filter'>
                    <div>
                         <span>Filter by</span>
                         <span style={{cursor: 'pointer', fontSize: '40px'}} onClick={() => {setShowFilter(false); sessionStorage.removeItem('filter')}}>{'\u00D7'}</span>
                    </div>
                    <div>
                         <span>Sort by</span>
                         <span>
                              <select value={popFilt} onChange={(e) => setPopFilt(e.target.value)}>
                                   <option></option>
                                   <option value="new">Newest</option>
                                   <option value="old">Oldest</option>
                              </select>
                         </span>
                    </div>
                    <div>
                         <button onClick={() => {setPopFilt("")}}>Reset Filters</button>
                    </div>
               </span>
          </div>
     </>
  )
}

export default Clients;