import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import Sidebar from '../Components/Sidebar';
import Topnav from '../Components/Topnav';

import '../Assets/Styles/Pages/Clients.css';
import '../Assets/Styles/Pages/Loyalty.css';
import '../Assets/Styles/Pages/Staffs.css';

function Staffs() {

     const auth = localStorage.getItem("auth");
     const navigate = useNavigate()
     
     useEffect(() => {
          !auth && navigate("/login")

          localStorage.removeItem('PSlide');
          sessionStorage.removeItem('filter');

          const get = async () => {
               await axios({
                    method: "get",
                    url: "https://api.destivictsolutions.com/users",
                    headers: {
                         "Content-Type": 'application/json',
                         "Accept": "*",
                         "Authorization": auth
                    }
               })
               .then(res => setData(res.data))
               .catch(error => console.log(error))
          }

          get();

     }, [auth, navigate])
     
     const [showFilter, setShowFilter] = useState(false);
     const [showAddStaff, setShowAddStaff] = useState(false)

     const [firstElement, setFirstElement] = useState(true);
     const [secondElement, setSecondElement] = useState(false);

     const [data, setData] = useState();

     const [searchItem, updateSearchItem] = useState("");

     const [popFilt, setPopFilt] = useState("");
     const [filtStatus, setFiltStatus] = useState("");

     const [phone, setPhone] = useState("");
     const [firstname, setFirstname] = useState("");
     const [lastname, setLastname] = useState("");
     const [email, setEmail] = useState("");
     const [role, setRole] = useState("");

     const [formConf, setFormConf] = useState(false);

/*      const { register, handleSubmit, reset, formState: { errors } } = useForm();
 */

     async function addStaff(e) {
          e.preventDefault();

          await axios({
            method: "post",
            headers: {
              "Content-Type": 'application/json',
              "Accept": "*"
            },
            url: "https://api.destivictsolutions.com/users",
            mode:'cors',
            data: {
               firstname: firstname,
               lastname: lastname,
               email: email,
               phone: phone,
               user_role: role,
               status: "in-active",
               joined_date: new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + new Date().getDate().toString(),
               last_seen: new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + new Date().getDate().toString()
          }
          })
          .then(res => {
               if (res.status == 200) {
                    setFormConf(true);
                    e.target.reset();
               }  
          })
          .catch(error => console.log(error))
     }

     let count = 0;

  return (
     <>
          <div id='Clients'>
               <Sidebar/>
               <main>
                    <Topnav/>
                    {firstElement ?
                         <div id='maincnt'>
                              <div id='maintop'>
                                   <span>Staffs {}</span>
                                   <input type='search' placeholder='Search by Email' onChange={(e) => updateSearchItem(e.target.value)}/>
                                   <span id='flt' onClick={() => {setShowFilter(true); sessionStorage.setItem('filter', 'open')}}>
                                        <i className="fa-solid fa-filter"></i>
                                        Filters
                                   </span>
                                   <button onClick={() => {setShowAddStaff(true); sessionStorage.setItem('staff', 'open')}}>+ Add new</button>
                              </div>
                              <table>
                                   <thead>
                                        <tr>
                                             <td>S/N
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Name
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Email
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Role
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Date Onboarded
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Last seen
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             <td>Status
                                                  <i className="fa-solid fa-down-long"></i>
                                             </td>
                                             {/* <td>
                                                  More
                                             </td> */}
                                        </tr>
                                   </thead>       
                                   <tbody>
                                        {data?.filter((dat) => (dat.email.toLowerCase().includes(searchItem.toLowerCase()) && dat) && (dat.status.toLowerCase().includes(filtStatus.toLowerCase()) && dat))
                                             .sort((a, b) => { return ((new Date(a.appoint_date).getFullYear().toString() + "-" + (new Date(a.appoint_date).getMonth() + 1).toString() + "-" + new Date(a.appoint_date).getDate().toString()) >= (new Date(b.appoint_date).getFullYear().toString() + "-" + (new Date(b.appoint_date).getMonth() + 1).toString() + "-" + new Date(b.appoint_date).getDate().toString()) && (popFilt == "new")) ? -1 : ((new Date(a.appoint_date).getFullYear().toString() + "-" + (new Date(a.appoint_date).getMonth() + 1).toString() + "-" + new Date(a.appoint_date).getDate().toString()) <= (new Date(b.appoint_date).getFullYear().toString() + "-" + (new Date(b.appoint_date).getMonth() + 1).toString() + "-" + new Date(b.appoint_date).getDate().toString()) && (popFilt == "old")) && -1})
                                             .map((dat, i) =>
                                                  <tr key={i}>
                                                       <td>{count++}</td>
                                                       <td>{dat.firstname + " " + dat.lastname}</td>
                                                       <td>{dat.email}</td>
                                                       <td>{dat.user_role}</td>
                                                       <td>{dat.joined_date.slice(0, 10)}</td>
                                                       <td>{dat.last_seen.slice(0, 10)}</td>
                                                       <td>{dat.status}</td>
                                                       {/* <td onClick={() => {setFirstElement(false); setSecondElement(true); localStorage.setItem("PSlide", 'Details')}}>
                                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                                       </td> */}
                                                  </tr>
                                             )}
                                   </tbody>
                              </table>
                         </div>
                    : "" }
                    {/* {secondElement ?
                         <div id='maindt'>
                              <div id='dtnav'>
                                   <span>
                                        <i onClick={() => {setFirstElement(true); setSecondElement(false); localStorage.removeItem('PSlide')}} className="fa-solid fa-arrow-left-long"></i>
                                        <p>Charlie Brakus</p>
                                   </span>
                                   <span>
                                        <i className="fa-solid fa-phone"></i>
                                        <i className="fa-solid fa-envelope"></i>
                                   </span>
                              </div>
                              <div id='dtmain'>
                                   <div id='dtmainhd'>Booking History</div>
                                   <table>
                                        <thead>
                                             <tr>
                                                  <td>Serial Number</td>
                                                  <td>Booking Number</td>
                                                  <td>Service Booked
                                                       <i className="fa-solid fa-down-long"></i>
                                                  </td>
                                                  <td>Status</td>
                                                  <td>Cost
                                                       <i className="fa-solid fa-down-long"></i>
                                                  </td>
                                             </tr>
                                        </thead>
                                        <tbody>
                                             <tr>
                                                  <td>1</td>
                                                  <td>#23106</td>
                                                  <td>Private Vehicle Rust Proofing</td>
                                                  <td>Pending</td>
                                                  <td>$150</td>
                                             </tr>
                                        </tbody>
                                   </table>
                              </div>
                         </div>
                    : "" } */}
               </main>
          </div>
          <div id='filtercv' style={{display: (showFilter == false ? 'none' : "grid")}}>
               <span id='filter'>
                    <div>
                         <span>Filter by</span>
                         <span style={{cursor: 'pointer', fontSize: '40px'}} onClick={() => {setShowFilter(false); sessionStorage.removeItem('filter')}}>{'\u00D7'}</span>
                    </div>
                    <div>
                         <span>Sort by</span>
                         <span>
                              <select value={popFilt} onChange={e => setPopFilt(e.target.value)}>
                                   <option></option>
                                   <option value="new">Newest</option>
                                   <option value="old">Oldest</option>
                              </select>
                         </span>
                    </div>
                    <div>
                         <span>Staus</span>
                         <span>
                              <select value={filtStatus} onChange={e => setFiltStatus(e.target.value)}>
                                   <option></option>
                                   <option value="active">Active</option>
                                   <option value="inactive">In-active</option>
                              </select>
                         </span>
                    </div>
                    <div>
                         <button onClick={() => {setPopFilt(""); setFiltStatus("")}}>Reset Filters</button>
                    </div>
               </span>
          </div>
          <div id='filtercv2' style={{display: (showAddStaff == false ? 'none' : "grid")}}>
               <form id='filter2' method='post' onSubmit={addStaff}>
                    {formConf && <div style={{color: "blue"}}>New staff added successfully...</div>}
                    <div>
                         <span>Firstname</span>
                         <input type='text' onChange={e => setFirstname(e.target.value)} required/>
                    </div> 
                    <div>
                         <span>Lastname</span>
                         <input type='text' onChange={e => setLastname(e.target.value)} required/>
                    </div> 
                    <div>
                         <span>Email</span>
                         <input type='email' onChange={e => setEmail(e.target.value)} required/>
                    </div> 
                    <div>
                         <span>Phone</span>
                         <input type='number' onChange={e => setPhone(e.target.value)} required/>
                    </div>
                    <div>
                         <span>Role</span>
                         <select value={role} onChange={e => setRole(e.target.value)} required>
                              <option></option>
                              <option value="Admin">Admin</option>
                              <option value="Customer Service">Customer Service</option>
                         </select>
                    </div> 
                    <div>
                         <button onClick={() => {setShowAddStaff(false); sessionStorage.removeItem('staff')}}>Cancel</button>
                         <button>Add Staff</button>
                    </div>
               </form>
          </div>
     </>
  )
}

export default Staffs;