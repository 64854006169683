import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import loginImg from '../Assets/Images/LoginImg.png';
import logo from '../Assets/Images/Logo.png';

import '../Assets/Styles/Pages/Login.css';

function Login() {

  const auth = localStorage.getItem("auth");
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')

  useEffect(() => {
    auth && navigate("/")
  }, [auth, navigate])

  async function login(e) {
    e.preventDefault();
    
    await axios({
      method: "post",
      url: "https://api.destivictsolutions.com/login",
      headers: {
        "Content-Type": 'application/json',
        "Accept": "*"
      },
      mode:'cors',
      data: {
        email: email,
        password: password
      }
    })
    .then(res => {
      localStorage.setItem("auth", res.data.token);
      localStorage.setItem("user", res.data.userID);
      navigate("/")
    })
    .catch(error => console.error(error))
  }
  return (
    <div id='login'>
      <span>
        <img alt='' src={loginImg}/>
      </span>
      <span id='formcover'>
        <span>
          <img alt='' src={logo}/>
          <p>Please, Login...</p>
          <form method='post' onSubmit={login}>
            <input type='email' onChange={e => setEmail(e.target.value)} required/>
            <input type="password" onChange={e => setPassword(e.target.value)} required/>
            {/*             
              <span>
                <input type='checkbox'/>
                <p>Remember me?</p>
              </span> 
            */}
            <button type='submit'>Sign in</button>
          </form>
        </span>
      </span>
    </div>
  )
}

export default Login