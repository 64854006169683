import { Routes, BrowserRouter, Route } from 'react-router-dom';

import Bookings from './Pages/Bookings.js';
import Loyalty from './Pages/Loyalty.js';
import Clients from './Pages/Clients.js';
import Pricing from './Pages/Pricing.js';
import Calendar from './Pages/Calendar.js';
import Staffs from './Pages/Staffs.js';
import Login from './Pages/Login.js';

import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Bookings/>}/>
        <Route exact path='/loyalty' element={<Loyalty/>}/>
        <Route exact path='/clients' element={<Clients/>}/>
        <Route exact path='/pricing' element={<Pricing/>}/>
        <Route exact path='/calendar' element={<Calendar/>}/>
        <Route exact path='/staffs' element={<Staffs/>}/>
        <Route exact path='/login' element={<Login/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
