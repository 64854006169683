import React from 'react';

import logo from '../Assets/Images/Logo.png'

import '../Assets/Styles/Components/Sidebar.css';

function Sidebar() {

     const logout = () => {
          localStorage.removeItem('auth')
     }

  return (
     <div id='sidebar'>
          <a id='logolnk' href='/'>
               <img alt='' src={logo}/>
          </a>
          <span id='navlink'>
               <a style={{backgroundColor: (window.location.pathname == "/" ? "#FF6A00" : "")}} href='/'>
                    <i class="fa-regular fa-pen-to-square"></i>
                    Bookings
               </a>
               <a style={{backgroundColor: (window.location.pathname == "/loyalty" ? "#FF6A00" : "")}} href='/loyalty'>
                    <i class="fa-solid fa-tag"></i>
                    Loyalty
               </a>
               <a style={{backgroundColor: (window.location.pathname == "/clients" ? "#FF6A00" : "")}} href='/clients'>
                    <i class="fa-solid fa-user"></i>
                    Clients
               </a>
               <a style={{backgroundColor: (window.location.pathname == "/calendar" ? "#FF6A00" : "")}} href='/calendar'>
                    <i class="fa-solid fa-calendar-days"></i>
                    Calendar
               </a>
               <a style={{backgroundColor: (window.location.pathname == "/pricing" ? "#FF6A00" : "")}} href='/pricing'>
                    <i class="fa-solid fa-dollar-sign"></i>
                    Pricing
               </a>
               <a style={{backgroundColor: (window.location.pathname == "/staffs" ? "#FF6A00" : "")}} href='/staffs'>
                    <i class="fa-solid fa-user"></i>
                    Staffs
               </a>
          </span>
          <a id='logout' href='/' onClick={logout}>
               <i class="fa-solid fa-right-from-bracket"></i>
               Logout
          </a>
     </div>
  )
}

export default Sidebar;